* {
  margin: 0;
  padding: 0;
  /* height: 100%; */
}
body {
  height: 100%;
}
.hide-resize-observer {
  display: flex !important;
}

.fullHeightContainer {
  min-height: 100vh;
  display: flex;
  /* flex-direction: column; */
}


html, body {
  margin: 0;
  padding: 0;
  width: 100%; /* Full width of the viewport */
  height: 100%; /* Full height of the viewport */
}






